import React from 'react';
import styled from '@emotion/styled';
import { Link as RouterLink, graphql } from 'gatsby';
import { Typography, Box, Button, Hidden } from '@mui/material';
import { FiArrowRight } from 'react-icons/fi';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../layout';
import CenteredContainer from '../components/CenteredContainer';

const Novosti = ({ data }) => {
  const [
    { istaknutaNovostNaslov, kratkiOpisIstaknutaVijest, istaknutaNovost, novostiNaslov, novosti },
  ] = data.allStrapiNovosti.nodes;

  return (
    <Layout offset={1}>
      <CenteredContainer>
        <TopContainer>
          <Box>
            <Typography variant="overline" color="textSecondary">
              {istaknutaNovostNaslov}
            </Typography>
            {istaknutaNovost.cover && (
              <Box mt={2}>
                <GatsbyImage
                  image={istaknutaNovost.cover.localFile.childImageSharp.gatsbyImageData}
                  alt="Slika istaknute vijesti"
                />
              </Box>
            )}
          </Box>
          <Box flex="1" ml={[0, 6]} mt={6}>
            <Typography variant="body2" color="textSecondary">
              {istaknutaNovost.published_at}
            </Typography>
            <Typography variant="h4">{istaknutaNovost.naslov}</Typography>
            <Box mt={3} mb={2}>
              <Typography variant="subtitle2" paragraph>
                {kratkiOpisIstaknutaVijest}
              </Typography>
            </Box>
            <Button
              variant="text"
              color="primary"
              component={RouterLink}
              to={istaknutaNovost.url}
              endIcon={<FiArrowRight />}
            >
              Procitaj više
            </Button>
          </Box>
        </TopContainer>
        <Box mt={[8, 12]} mb={[0, 6]} width="100%">
          <Typography variant="h2" color="primary">
            {novostiNaslov}
          </Typography>
        </Box>
        <LatestNewsContainer>
          {novosti.map(({ id, naslov, published_at: publishedAt, url }) => (
            <Article key={id}>
              <Box width={['100%', '90%']}>
                <Typography variant="body2" color="textSecondary">
                  {publishedAt}
                </Typography>
                <RouterLink to={url}>
                  <Typography variant="h5">{naslov}</Typography>
                </RouterLink>
              </Box>
              <Hidden xlDown>
                <RouterLink to={url}>
                  <FiArrowRight fontSize={24} />
                </RouterLink>
              </Hidden>
            </Article>
          ))}
        </LatestNewsContainer>
      </CenteredContainer>
    </Layout>
  );
};

const Article = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  max-width: 772px;
  width: 100%;
  margin: 0 auto;
  ${({ theme }) => `
  margin-top: ${theme.spacing(4)};
  padding-bottom: ${theme.spacing(4)};
  border-bottom: 1px solid ${theme.palette.text.primary};
  a {
    color: ${theme.palette.text.primary};
    margin-top: ${theme.spacing(4)};
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
  `}
`;

const LatestNewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  ${({ theme }) => `
  img {
    border-radius: ${theme.shape.borderRadius};
  }
  `}
`;

export const query = graphql`
  query NovostiQuery {
    allStrapiNovosti {
      nodes {
        novostiNaslov
        istaknutaNovostNaslov
        kratkiOpisIstaknutaVijest
        istaknutaNovost {
          id
          naslov
          cover {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, width: 540, quality: 70)
              }
            }
          }
          published_at(locale: "hr", formatString: "DD/MM/yyyy")
          url
        }
        novosti {
          id
          naslov
          url
          published_at(locale: "hr", formatString: "DD/MM/yyyy")
        }
      }
    }
  }
`;

export default Novosti;
